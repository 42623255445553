import React, { Component } from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import PageCover from '../components/page-cover'
import Archive from '../components/archive'
import Testimonials from '../components/testimonials'
import Services from '../components/services'

class Page extends Component {

  render() {
    let { page } = this.props.data

    return (
      <>
        <SEO title="Page" keywords={[`gatsby`, `application`, `react`]} />
        <PageCover background={page.acf.background && page.acf.background.localFile.childImageSharp.original.src}>
          <div className='container'>
            <div className='col col-lrg-third'>
              <p>{ page.acf.number }</p>
            </div>
            <div className='col col-half col-lrg-2thirds'>
              <h1>{ page.acf.title }</h1>
            </div>
          </div>
        </PageCover>
        <div className='page-cover__under'>
          { page.acf.sections && page.acf.sections.map((el, i) => {
            switch (el.__typename) {
              case 'WordPressAcf_basic':
                return (
                  <section key={i} className='container'>
                    <div className='col col-lrg-third'>
                      <h2>{ el.title }</h2>
                      { el.subtitle && <div dangerouslySetInnerHTML={{__html: el.subtitle }} /> }
                    </div>
                    <div className='col col-75 col-lrg-2thirds'>
                      { el.image && <img src={el.image.localFile.childImageSharp.original.src} alt={el.title} /> }
                      <div className='col-flex'>
                        <div dangerouslySetInnerHTML={{__html: el.content }} />
                        { el.split_column && <div dangerouslySetInnerHTML={{__html: el.second_column }} /> }
                      </div>
                    </div>
                  </section>
                )
              case 'WordPressAcf_services':
                return (<Services key={i} {...el} />)
              case 'WordPressAcf_testimonials':
                return (<Testimonials key={i} {...el} />)
              case 'WordPressAcf_news_archive':
                return (<Archive key={i} />)
              default:
                break
            }
            return false
          })
          }
        </div>
      </>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query currentPageQuery($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      acf {
        number
        title
        sections: sections_page {
          __typename
          ... on WordPressAcf_basic {
            split_column
            title
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            content
            subtitle
            second_column
          }
          ... on WordPressAcf_services {
            title
            sections {
              title
              first_column
              second_column
            }
          }
          ... on WordPressAcf_testimonials {
            title
            testimonials {
              text
              person
            }
          }

        }
        background {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`
