import React, { Component } from 'react'

class Services extends Component {
  state = {
    current: 0,
  }
  render() {
    let { title, sections } = this.props
    let { current } = this.state

    return (
      <section className='container'>
        <div className='col col-lrg-third'>
          <h2>{ title }</h2>
          { sections && sections.map((el, i) => {
            let props = {
              key: i,
              style: {margin: 0, textDecoration: i === current && 'underline', cursor: 'pointer'},
              onClick: () => this.setState({ current: i }),
            }
            return (
              <p {...props}>
                { el.title }
              </p>
            )
          }) }
        </div>
        <div className='col col-75 col-lrg-2thirds'>
          <div className='col-flex'>
            { sections && sections[current] && (
              <>
                <div dangerouslySetInnerHTML={{__html: sections[current].first_column }} />
                <div dangerouslySetInnerHTML={{__html: sections[current].second_column }} />
              </>
            ) }
          </div>
        </div>
      </section>
    )
  }
}

export default Services
