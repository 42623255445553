import React, { Component } from 'react'
import { Transition } from 'react-transition-group'

class Testimonials extends Component {

  state = {
    active: 0,
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      let { active } = this.state
      if (++active >= this.props.testimonials.length) active = 0
      this.setState({ active })
    }, 7000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  _renderItems() {
    const duration = 300;

    const defaultStyle = {
      transition: `opacity ${duration}ms linear`,
      opacity: 0,
      visibility: 'hidden',
      position: 'absolute',
    }

    const transitionStyles = {
      entering: { opacity: 0, visibility: 'visible' },
      entered:  { opacity: 1, visibility: 'visible' },
      exiting: { opacity: 0, visibility: 'visible' },
    }

    return this.props.testimonials.map((item, i) => (
      <Transition in={this.state.active === i} timeout={duration} key={i}>
        { state => (
          <div style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}>
            <p>{ item.text }</p>
            <p>— { item.person }</p>
            <div>
              { this.props.testimonials.map((el, index) => {
                return (
                  <svg key={index} xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" style={{marginRight: 8, cursor: 'pointer'}} onClick={() => this.setState({ active: index })}>
                    <g fill={i === index ? "#707070" : "#fff"} stroke="#707070" strokeWidth="1">
                      <circle cx="5.5" cy="5.5" r="5.5" stroke="none"/>
                      <circle cx="5.5" cy="5.5" r="5" fill="none"/>
                    </g>
                  </svg>
                )
              }) }
            </div>
          </div>
        ) }
      </Transition>
    ))
  }

  render() {
    return (
      <section className='container'>
        <div className='col col-lrg-third'>
          <h3>{ this.props.title }</h3>
        </div>
        <div className='col col-75 col-lrg-2thirds' style={{ position: 'relative', height: 250 }}>
          { this._renderItems() }
        </div>
      </section>
    )
  }
}

export default Testimonials
